import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dukandarInvoiceState:[
    ],
    newList: [],
    ordersList: [
      {
        'id': 1,
        'status': 0,
        'mobile': '07504549598',
        'amount': 25000,
        'customerName': 'صالح جمعة',
        'address': 'هەوليرو گوندي ايتالي ٢',
        'deliveryDat':null
      },
 
    ],
    cities: [
      {
        'id': 1, 'city': 'Erbil',
      },
      {
        'id': 2, 'city': 'Sulimany',
      },
      {
         'id': 3, 'city': 'Baghdad',
      },
      {
        'id': 4, 'city': 'Samal',
      }

      
    ],
    myStatus: [
      {
        'id': 0, 'status': 'NEW',
      },
      {
        'id': 1, 'status': 'SHIPPED',
      },
      {
         'id': 2, 'status': 'ARRIVED',
      },
      {
        'id': 3, 'status': 'PAID',
      },
      {
        'id': 4, 'status': 'REJECTED',
      },
      {
        'id': 5, 'status': 'PENDING',
      },
      {
        'id': 6, 'status': 'READY',
      },

      
    ],
    listForDriver: [
  
    ],
    listForDukandar: [
      {
        'id': 1,
        'customer': '',
        'city': '',
        'address': '',
        'mobile': '',
        'price': 0,
        'commisson': 0,
        'status': 0
      }
    ],
    listForDukandarForPrint: [],
    stopUsingFilterdOrderListForDateButtons: false,
    isItListForDukandar: true,
    orderItem: Object,
    filteredOrderList: [],
    copyOfFilteredOrderList: [],
    driverList: [],
    dukandarList: [],
    userId: 0,
    userName: '',
    firstName: '',
    lastName: '',
    token:'',
    url: 'https://driver2.deliveryfast.net/api',
    //url: 'https://localhost:44307/api',
    //url: 'https://duho.deliveryfast.net/api',

    DriverId: 0,
    DriverName: '',
    userType: '',
    loadDukandarForAdmin: false,
    dukandarNameForAdmin:'',
    loginStatus: false,
    showSnackbar: false,
  },
  mutations: {
    SET_ITEMS (state, posts) {
      state.ordersList = posts
    },
    SET_ORDER_ITEM (state, order) {
      state.orderItem = order
    },
    SET_DRIVERS(state, payload) {
      state.driverList = payload
    },
    SET_CITIES(state, payload) {
      state.cities = payload
    },
    UPDATE_SNACK(state) {
      state.showSnackbar = true
    },
    SET_NEW_LIST(state, payload) {
      state.newList = payload
    },
    SET_DUKANDAR(state, payload) {
      state.dukandarList = payload
    },
    SET_USERID (state, USERID) {
      state.userId = USERID
    },
    LOG_OUT (state) {
      state.userId = ''
      state.firstName= ''
      state.lastName= ''
      state.userName= ''
      state.token= ''
      state.userType = ''
      localStorage.removeItem('userId')
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
      localStorage.removeItem('userName')
      localStorage.removeItem('token')
      localStorage.removeItem('userType')
   

    },
    REFRESH (state) {
      state.userId = localStorage.getItem('userId')
      state.firstName= localStorage.getItem('firstName')
      state.lastName= localStorage.getItem('lastName')
      state.userName= localStorage.getItem('userName')
      state.token= localStorage.getItem('token')
      state.userType = localStorage.getItem('userType')
    },
    SET_USER (state, payload) {
      state.userId = payload.id
      state.firstName= payload.firstName
      state.lastName= payload.lastName
      state.userName= payload.username
      state.token= payload.token
      state.userType = payload.userType

      // local Storge
      localStorage.setItem('userId', payload.id)
      localStorage.setItem('token', payload.token)
      localStorage.setItem('userType', payload.userType)
      localStorage.setItem('firstName', payload.firstName)
      localStorage.setItem('lastName', payload.lastName)
      localStorage.setItem('userName', payload.username)

    },
    CLEAR_ORDER_DRIVER(state,payload) {
      state.ordersList = state.ordersList.filter(x=> x.status == payload)
    },
    SET_DRIVER_NAME (state, payload) {
      state.DriverName = payload
    },
    SET_COPY_OF_FILTERED_ORDERS (state) {
      state.copyOfFilteredOrderList = state.filteredOrderList
    },
    SET_DRIVER_ID(state, payload ) {
      state.DriverId = payload
    },
    SET_USER_TYPE(state, payload ) {
      state.userType = payload
    },
    UPDATE_FILTERD_ORSER_LIST(state, payload ) {
      state.filteredOrderList = payload
    },
    SET_DUKANDAR_FOR_ADMIN(state, payload ) {
      state.dukandarNameForAdmin = payload
      state.loadDukandarForAdmin= true
    },
    RESET_DUKANDAR_FOR_ADMIN(state ) {
      state.dukandarNameForAdmin = ''
      state.loadDukandarForAdmin= false
    },
    ADD_EMPTY_ITEM_TO_DUKANDAR_LIST(state ) {
      var item= {
        'id': 2,
        'customer': '',
        'city': '',
        'address': '',
        'mobile': '',
        'price': 0,
        'commisson': 0,
        'status': 0
      }
    item.id= state.listForDukandar.length+1
     state.listForDukandar.push(item)
    },
    SET_CITY_ORDERS(state, payload) {
        // state.listForDDriver= payload
        state.listForDukandar= payload
    },
    REMOVE_ITEM_FROM_DUKANDAR_LIST(state, payload) {
      var index = state.listForDukandar.findIndex(x=> x.id == payload)
      state.listForDukandar.splice(index,1)
      if (state.isItListForDukandar) {
        for (let i = 0; i <  state.listForDukandar.length; i++) {
          state.listForDukandar[i].id= i+1
        }
      }
     
     
    },
    RESET_SEARCH_ITEM_ORDER(state) {
      state.orderItem = null
    },
    SET_DUKANDAR_INVOICE(state, payload) {
      state.dukandarInvoiceState = payload
    },
    REMOVE_ORDER(state, payload ) {
      //console.log(payload);
      // payload is order id
      //const found = state.ordersList.find(element => element.id == payload);
      //console.log(found);
      var index = state.ordersList.findIndex(x=> x.id == payload)
      //console.log(index);
      //console.log(state.ordersList.slice(index,1));
      //state.ordersList= state.ordersList.slice(index,1)
      state.ordersList.splice(index,1)
      index = state.filteredOrderList.findIndex(x=> x.id == payload)
      state.filteredOrderList.splice(index,1)

      
    },
    UPDATE_STATUS (state, payload) {
      // console.log('sta');
      // console.log(payload.id)
      // console.log(payload.status)
      //state.userId = 2
      // let myArray = [
      //   {id: 0, name: "Jhon"},
      //   {id: 1, name: "Sara"},
      //   {id: 2, name: "Domnic"},
      //   {id: 3, name: "Bravo"}
      // ],
          
      //Find index of specific object using findIndex method.    
      // objIndex = myArray.findIndex((obj => obj.id == 1));
      
      // //Log object to Console.
      // console.log("Before update: ", myArray[objIndex])
      
      // //Update object's name property.
      // myArray[objIndex].name = "Laila"
      
      // //Log object to console again.
      // console.log("After update: ", myArray[objIndex])

      const objIndex = state.ordersList.findIndex((obj => obj.id == payload.id));
      //console.log(objIndex);
      state.ordersList[objIndex].deliveryDat= new Date()
      state.ordersList[objIndex].status= payload.status
      if (payload.status == 0) {
        state.ordersList[objIndex].deliveryDat= null
      }
      //console.log( state.ordersList[objIndex]);
    },
    SET_IS_IT_LIST_FOR_DUKANDAR_TO_FALSE(state) {
        state.isItListForDukandar= false
    },
    SET_IS_IT_LIST_FOR_DUKANDAR_TO_TRUE(state) {
        state.isItListForDukandar= true
    },
    RESET_DUKANDAR_LIST(state) {
        state.listForDukandarForPrint= state.listForDukandar
        state.listForDukandar=[]
        state.showSnackbar= true
    },
    TOOGLE_STOP_FILITERING(state) {
        state.stopUsingFilterdOrderListForDateButtons= !state.stopUsingFilterdOrderListForDateButtons
    }
  },
  actions: {
    async getDukandarorderByDate({commit, state}, payload) {
      const headers = {
        "Content-Type": "application/json"
    }
      axios.post(state.url +  "/Trans/dukandar-paid-by-date",payload , headers ) 
      .then(response => {
        commit('SET_NEW_LIST', response.data)
        console.log(response.data);
        
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async getTransport({commit, state}, payload) {
      axios.get(state.url +  "/Customers/one-orders/"+ payload ) 
      .then(response => {
        commit('SET_ORDER_ITEM', response.data)
        //console.log(response.data);
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async getTransportByPhone({commit, state}, payload) {
      const headers = {
        "Content-Type": "application/json"
    }
    //const data= payload
      axios.post(state.url +  "/Trans/search-phone", payload, headers ) 
      .then(response => {
        commit('SET_ORDER_ITEM', response.data)
        //console.log(response.data);
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async getDriverAllTransport({commit, state}, payload) {
      axios.get(state.url +  "/Trans/driver-all-transport/"+ payload ) 
      .then(response => {
        commit('SET_ITEMS', response.data)
        //console.log(response.data);
      })
      .catch(error => { 
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async getDukandarInvoicesPaid({commit, state}, payload) {
      axios.get(state.url +  "/Trans/dukandar-paid-groupby/"+ payload ) 
      .then(response => {
        commit('SET_DUKANDAR_INVOICE', response.data)
        //console.log(response.data);
      })
      .catch(error => { 
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async getDukandarAllTransport({commit, state}, payload) {
      axios.get(state.url +  "/Trans/dukandar-all-transport/"+ payload ) 
      .then(response => {
        commit('SET_ITEMS', response.data)
        //console.log(response.data);
      })
      .catch(error => { 
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async loadPosts ({commit, state}) { 
    var id= state.DriverId ==0? state.userId : state.DriverId

     // if (id==0) {
      if ((state.userType != 'driver' && state.userType != 'admin') || state.loadDukandarForAdmin) { 
        if (state.loadDukandarForAdmin)
          id= state.dukandarNameForAdmin
        else 
          id= state.userName
        //console.log(id);
        axios.get(state.url + "/Customers/all-orders-string/"+ id ) 
        .then(response => {
          commit('SET_ITEMS', response.data)
          // console.log(response.data);
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
      } else {
       //console.log(id);
        axios.get(state.url + "/Customers/all-orders/"+ id ) 
        .then(response => {
          commit('SET_ITEMS', response.data)
          // console.log(response.data);
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
      }
  
    },
    async bringOrderBelongToCityAction({commit, state}, payload) {
      axios.get(state.url +  "/Trans/get-city-items-for-driver/"+ payload ) 
      .then(response => {
        commit('SET_CITY_ORDERS', response.data)
        //console.log(response.data); 
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async loadDrivers ({commit, state}) { 
      axios.get(state.url + "/Driver")
      .then(response => {
        commit('SET_DRIVERS', response.data)
        // console.log(response.data);
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async loadCities ({commit, state}) { 
      axios.get(state.url + "/Town")
      .then(response => {
        commit('SET_CITIES', response.data)
        // console.log(response.data);
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async loadDDukandars ({commit, state}) { 
      axios.get(state.url + "/Dukandar")
      .then(response => {
        commit('SET_DUKANDAR', response.data)
        // console.log(response.data);
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
      },
    async updateNote ({ state }, payload) { 
      const  data= {
        'id': payload.id, //23603,
        'note': payload.note //4
      }
      const headers = {
        "Content-Type": "application/json"
    }
    //commit('UPDATE_STATUS', {'id': payload.id, 'status': payload.status})
      axios.post( state.url + "/Trans/update-note", data, headers)
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async updateStatus ({ commit, state }, payload) { 
      const  data= {
        'id': payload.id, //23603,
        'status': payload.status //4
      }
      const headers = {
        "Content-Type": "application/json"
    }
    commit('UPDATE_STATUS', {'id': payload.id, 'status': payload.status})
      axios.post( state.url + "/Customers/update-status", data, headers)
      .then(response => {
        commit('UPDATE_STATUS', data)
        console.log(response.data);
        //console.log('gfgfgf');
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async updateVoiceNote ({state }, payload) { 
      // const  data= {
      //   'id': payload.id, //23603,
      //   'voiceNote': payload.voiceNote //4
      // }
      console.log(payload.voiceNote);

      //let blob = new Blob(payload.voiceNote, { type: "audio/ogg" });
      let file = new File([payload.voiceNote], 'recording.ogg');

      const data = {
        "Id" : "22",
      };

      const formData = new FormData();
      formData.append('files.file', file);
      formData.append('data', JSON.stringify(data));
      const headers = {
        "Content-Type": "'multipart/form-data;"
    }
    //commit('UPDATE_STATUS', {'id': payload.id, 'status': payload.status})
      axios.post( state.url + "/Trans/update-voice-note", formData, headers)
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async updateTown ({ commit, state }, payload) { 
      const  data= {
        'id': payload.id, //23603,
        'city': payload.city //4
      }
      const headers = {
        "Content-Type": "application/json"
    }
    //commit('UPDATE_STATUS', {'id': payload.id, 'status': payload.status})
      axios.post( state.url + "/Town", data, headers)
      .then(()=> {
        commit('UPDATE_SNACK')
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async deleteUser ({ state }, payload) { 
      // const  data= {
      //   'id': payload //23603,
      // }
      const headers = {
        "Content-Type": "application/json"
    }
    //commit('UPDATE_STATUS', {'id': payload.id, 'status': payload.status})
      axios.delete( state.url + "/Dukandar/"+ payload, headers)
      .then(()=> {
        //commit('UPDATE_SNACK')
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async deleteOrder ({ state }, payload) { 
      // const  data= {
      //   'id': payload //23603,
      // }
      const headers = {
        "Content-Type": "application/json"
    }
    //commit('UPDATE_STATUS', {'id': payload.id, 'status': payload.status})
      axios.delete( state.url + "/Trans/"+ payload, headers)
      .then(()=> {
        //commit('UPDATE_SNACK')
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async clearDriverOrdersAction ({ commit, state }, payload) { 
     if (payload == 3 || payload == 4)  {
      var  data= {
        'orders': state.filteredOrderList.map(x=> x.id) //state.ordersList.filter(x=>x.status== payload &&(!x.driverClearnce || x.driverClearnce == null)).map(x=> x.id)
        
      } 
      // if (state.loadDukandarForAdmin) {
      //   data= {
      //     'orders':  state.ordersList.filter(x=>x.status== payload && x.driverClearnce).map(x=> x.id)
          
      //   }
      // }
      const headers = {
        "Content-Type": "application/json"
    }
    const routeName= state.loadDukandarForAdmin? "/Driver/dukan-clear" : "/Driver/driver-clear"
      axios.post( state.url + routeName, data, headers)
      .then(response => {
       // commit('CLEAR_ORDER_DRIVER', {'orders': state.ordersList.filter(x=>x.status == payload).map(x=> ({id: x.id}))})
        commit('CLEAR_ORDER_DRIVER', {'orders': state.filteredOrderList})
        console.log(response.data);
        //console.log('gfgfgf');
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
     }
  
    },
    async saveDukandarList ({ commit, state }, payload) { 

    // console.log(payload);
      const headers = {
        "Content-Type": "application/json"
    }
    const data={
     'ListForDukandars': state.listForDukandar,
     'DukandarId': payload
    }
      //console.log(state.orderItem);
      axios.post(state.url + "/Trans/add-new-orders-to-dukandar", data, headers)
      .then(response => {
       commit('RESET_DUKANDAR_LIST', response.data)

      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async saveCurrentDukandar ({  state }, payload) { 

      const headers = {
        "Content-Type": "application/json"
    }
      //console.log(state.orderItem);
      axios.post(state.url + "/Dukandar", payload, headers)
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async saveOrder ({  state }) { 

      state.amount = parseInt(state.amount)
      const headers = {
        "Content-Type": "application/json"
    }
      //console.log(state.orderItem);
      axios.post(state.url + "/Trans", state.orderItem, headers)
      // .then(response => {
      //  commit('SAVE_ORDER', response.data)

      // })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async updatePassword ({  state }, payload) { 
      console.log(payload);
      const  data= {
        'id': payload.id, //23603,
        'password': payload.password, //4
        'mobile': payload.mobile,
        'driverName': payload.driverName,
        'address': payload.address
      }
      const headers = {
        "Content-Type": "application/json"
    }
    //commit('SET_USERID', 22)
      axios.post(state.url + "/Driver", data, headers)
      // .then(response => {
      //  // commit('UPDATE_STATUS', data)
      //   console.log('OK');
      //   //console.log('gfgfgf');
      // })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
    },
    async login ({commit, dispatch, state }, payload) { 
     
      const  data= {
        'userName': payload.name, //23603,
        'password': payload.password //4
      }
      const headers = {
        "Content-Type": "application/json"
    }
      axios.post(state.url + "/Customers/login", data, headers)
      .then(response => {
        commit('SET_USER', response.data)

        
    // if (state.DriverId != 0) {
    //   commit('SET_USER_TYPE', 'driver')
    // } else if (state.userId !=0) {
    //   commit('SET_USER_TYPE', 'admin')
    // } else {
    //   commit('SET_USER_TYPE', 'dukandar')
    // }


        // console.log(response.data);
        state.loginStatus= true
        dispatch('loadPosts')
      })
      .catch(error => {
        this.errorMessage = error.message;
        state.loginStatus= false
        //console.error("There was an errory!", error);
      });
      },
      test ({ commit }, payload) {
        commit('SET_USERID', payload)
        //console.log(payload);
      },
      refresh ({ commit }) {
        commit('REFRESH')
      },
     updateDriverId ({commit}, payload) {
        commit('SET_DRIVER_ID', payload)
      },
     setdukandaeNameAndStatusForAdmin ({commit}, payload) {
       //console.log(payload);
        commit('SET_DUKANDAR_FOR_ADMIN', payload)
      },
     resetDukandaeNameAndStatusForAdmin ({commit}) {
       //console.log(payload);
        commit('RESET_DUKANDAR_FOR_ADMIN')
      },
      reomveOrder ({commit}, payload) {
        //console.log(payload);
        commit('REMOVE_ORDER', payload)
      },
      updateFilteredorderList ({commit}, payload) {
        //console.log(payload);
        commit('UPDATE_FILTERD_ORSER_LIST', payload)
      },
      logout({commit}) {
        commit("LOG_OUT")
      },
      resetSearchItemOrder({commit}){
        commit('RESET_SEARCH_ITEM_ORDER')
      },
      addEmptyItemToDukandarList({ commit }) {
        commit('ADD_EMPTY_ITEM_TO_DUKANDAR_LIST')
      },
      removeOrderItemAction({commit}, payload) {
          commit('REMOVE_ITEM_FROM_DUKANDAR_LIST', payload)
      },
      setIsItListForDukandarToFalse({commit}) {
        commit('SET_IS_IT_LIST_FOR_DUKANDAR_TO_FALSE')
      },
      setIsItListForDukandarToTrue({commit}) {
        commit('SET_IS_IT_LIST_FOR_DUKANDAR_TO_TRUE')
      },
      toggleStopFIlteringForDatebuttons({commit}) {
        commit('TOOGLE_STOP_FILITERING')
      },
      updateCopyOfFiteredOrders({commit}) {
        commit('SET_COPY_OF_FILTERED_ORDERS')
      },
      addOrderToDriverAction({ commit, state}, payload) {
          
      // console.log(payload);
        const headers = {
          "Content-Type": "application/json"
      }
      const data={
      'ListForDukandars': state.listForDukandar,
      'DriverId': payload
      }
        //console.log(state.orderItem);
        axios.post(state.url + "/Trans/add-drivers-to-orders", data, headers)
        .then(response => {
         commit('RESET_DUKANDAR_LIST', response.data)

        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        });
      }

  },
  modules: {
  },
   getters: {
    posts: state => {
      return state.ordersList;
    },
    paidAmount: state => {
      return state.ordersList.filter(x=>x.status==3 && (!x.driverClearnce || x.driverClearnce == null)).reduce((acc, item) => acc + item.amount, 0);
    },
    paidReadyAmount: state => {
      return state.ordersList.filter(x=>x.status==3 && x.driverClearnce).reduce((acc, item) => acc + item.amount, 0);
    },
    paidAmountWithCommission: state => {
      return state.ordersList.filter(x=>x.status==3 && (!x.driverClearnce || x.driverClearnce == null))
      .reduce((acc, item) => acc + item.amount + item.commission, 0);
    },
    paidReadyAmountWithCommission: state => {
      return state.ordersList.filter(x=>x.status==3 && x.driverClearnce)
      .reduce((acc, item) => acc + item.amount + item.commission, 0);
    },
    paidCounts: state => {
      return state.ordersList.filter(x=>x.status==3 && (!x.driverClearnce || x.driverClearnce == null)).length;
    },
    newCounts: state => {
      return state.ordersList.filter(x=>x.status <=2 && (!x.driverClearnce || x.driverClearnce == null)).length;
    },
    rejectCounts: state => {
      return state.ordersList.filter(x=>x.status==4 && (!x.driverClearnce || x.driverClearnce == null)).length;
    },
    rejectAndReadyCounts: state => {
      return state.ordersList.filter(x=>x.status==4 && x.deliveryDat != null && x.driverClearnce).length;
    },
    pendingCounts: state => {
      return state.ordersList.filter(x=>x.status==5 && (!x.driverClearnce || x.driverClearnce == null)).length;
    },
    readyCounts: state => {
      return state.ordersList.filter(x=>x.status==3 && (x.driverClearnce)).length;
    },
    itemyPhone: (state) => (payload)=> { 
      if (state.ordersList.filter(x=>x.mobile == payload).length ==0)
      return state.orderItem
      else
         return state.ordersList.filter(x=>x.mobile == payload);
    },
    isThatDateHaveItems: (state) => (payload)=> { 
      // console.log(state.filteredOrderList);
      // if (!state.stopUsingFilterdOrderListForDateButtons) {

      //   return state.filteredOrderList.filter(x=> new Date(x.deliveryDat).toDateString() == new Date(payload).toDateString()).length
      // } else {
      //   return state.copyOfFilteredOrderList.filter(x=> new Date(x.deliveryDat).toDateString() == new Date(payload).toDateString()).length
      // }
        // console.log('her I am');
        try {
        
          var y= state.copyOfFilteredOrderList.filter(x=> new Date(x.deliveryDat).toDateString() == new Date(payload).toDateString()).length
          //console.log(y);
          return y

        }
        catch(err) {
        return err.message
        }
      // }
     
      
      },
    isThatDateHaveItemsAmount: (state) => (payload)=> { 

        try {
        
          var y= state.copyOfFilteredOrderList
          .filter(x=> new Date(x.deliveryDat).toDateString() == new Date(payload).toDateString())
          .reduce((acc, item) => acc + item.amount, 0)
          //console.log(y);
          return y

        }
        catch(err) {
        return err.message
        }
      // }
     
      
      },
    isThatDateHaveItemsCommision: (state) => (payload)=> { 

        try {
        
          var y= state.copyOfFilteredOrderList
          .filter(x=> new Date(x.deliveryDat).toDateString() == new Date(payload).toDateString())
          .reduce((acc, item) => acc + item.commission, 0)
          //console.log(y);
          return y

        }
        catch(err) {
        return err.message
        }
      // }
     
      
      },
      
    getDukandarNameById: (state) => (payload)=> { 

        try {
        
          var y= state.dukandarList
          .filter(x=> x.id == payload).map(x=>x.dukandarName)[0]
          console.log(y);
          return y

        }
        catch(err) {
        return err.message
        }
      // }
     
      
      },
    getDriverNameById: (state) => (payload)=> { 

        try {
        
          var y= state.driverList
          .filter(x=> x.id == payload).map(x=>x.driverName)[0]
          console.log(y);
          return y

        }
        catch(err) {
        return err.message
        }
      // }
     
      
      },

  },
})
